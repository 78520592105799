/* eslint-disable no-unused-expressions */
import {
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import iconDependency from '@/dependencies/iconDependency'
import {
  openTicketUseCase,
  authUseCase
} from '@/domain/usecase'

const modules = 'deliveryItem'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties

    const checkResponse = (data: any) => {
      $toast.add({
        severity: 'error',
        detail: data.message,
        group: 'bc',
        life: 3000
      })
    }

    const getOpenTicketUser = async () => {
      const responseAllUser = await openTicketUseCase.getAllOtUser()
      if (!responseAllUser.error) {
        const account = responseAllUser.result[0]
        const dataOpenTicket = {
          email: `${account.Username}`,
          password: account.Password,
          host: `${account.Url}`
        }
        await store.dispatch('setAppOpenticketAccount', dataOpenTicket)
      }
    }

    const getActiveUserProfile = async (id: any) => {
      const dataProfile = await authUseCase.getLoginUser(id)
      // console.log('dataProfile', dataProfile.result)
      return dataProfile.result
    }

    const setStore = async (data: any) => {
      // store.dispatch('setIsAuthenAuthenticated', true)
      // store.dispatch('setDateAuthenAuthenticated', moment().format())
      // if (data.result.Menu) {
      //   store.dispatch('setMenu', mappingMenu(data.result.Menu))
      // }
      // if (data.result.MenuKurir) {
      //   store.dispatch('setMenuKurir', mappingMenu(data.result.MenuKurir))
      // }
      // if (data.result.Route) {
      //   await store.dispatch('setRoute', data.result.Route)
      // }
      // const responseCourier = await getCourier()
      // await store.dispatch('kurir/setKurir', responseCourier)
      await store.dispatch('setCustomView', data.result.CustomView)
      await store.dispatch('showAdvertise')
      await getOpenTicketUser()
    }

    const login = async () => {
      try {
        const response = await authUseCase.sendingAuth({
          code: route.query.code && typeof route.query.code === 'string' ? route.query.code : '',
          scope: route.query.scope && typeof route.query.scope === 'string' ? route.query.scope : '',
          state: route.query.state && typeof route.query.state === 'string' ? route.query.state : ''
        })
        if (!response.error) {
          store.dispatch('setIsAuthenAuthenticated', true)
          store.dispatch('setDateAuthenAuthenticated', moment().format())
          await Promise.all([
            await store.dispatch('setDataToken', response.result),
            await store.dispatch('appActiveUser/setUserInfo'),
            await store.dispatch('appActiveUser/setUserAgentInfo'),
            await store.dispatch('globalSetting/setGlobalSetting'),
            store.dispatch('showAdvertise')
          ])
          window.location.href = '/hjex/'
        } else {
          router.push({
            name: 'home'
          })
          store.dispatch('logoutAccount')
          $toast.add({
            severity: 'error',
            detail: 'Token Kadaluwarsa',
            group: 'bc',
            life: 3000
          })
        }
      } catch (err) {
        console.log('err r', err)
      }
    }
    onMounted(() => {
      // console.log('ini router', Object.keys(route.query))
      if (Object.keys(route.query).length > 0) {
        login()
      } else {
        router.push({
          name: 'home'
        })
        store.dispatch('logoutAccount')
      }
    })
    return {}
  }
}
